import { Embed, Formatter, Updater, addToObjectLink, findTargetElementFromEvent, getDocumentTranslations, getLinkedObjects } from './_imports.js';




document.addEventListener('click', function (event) {

    let imageswitch = findTargetElementFromEvent(event, 'data-alvine-role', 'image-switch');
    if (imageswitch) {
        event.preventDefault();

        let src = imageswitch.getAttribute('data-alvine-image');
        let image = document.querySelector('[data-alvine-role="image-switch-target"]');
        if (image) {
            image.src = src;
        }

    }

    let family1ListButton = findTargetElementFromEvent(event, 'data-alvine-role', 'family1-list-button');
    if(family1ListButton){
        event.preventDefault();

        let parent = family1ListButton.closest('.family1ButtonsList');

        parent.querySelectorAll('[data-alvine-role="family1-list-button"]').forEach(element => {
            element.classList.remove('active');
            element.classList.remove('btn-secondary');
            element.classList.add('btn-outline-secondary');
        });

        family1ListButton.classList.add('btn-secondary');
        family1ListButton.classList.add('active');

        /**
         * 
         */ 
         let family1VariantMapJson = document.querySelector('[data-alvine-role="family1VariantMap"]');
         let family1Variants = JSON.parse(family1VariantMapJson.textContent);

        

        let new_variant = [];
        document.querySelectorAll('[data-alvine-role="family1-list-button"].active').forEach(element => {
            new_variant.push(element.getAttribute('data-alvine-value'));
        });
        let key = new_variant.join('_');

        let nextURL = family1Variants.variantMap[key];
        if(nextURL){
            window.location=nextURL;
        }else{
            document.querySelector('[data-alvine-role="combination-note"]').style.display = 'block';
        }
        

    }
});

/**
 * Produkt Familie
 */

let family1VariantMapJson = document.querySelector('[data-alvine-role="family1VariantMap"]');
if (family1VariantMapJson) {
    let family1VariantMap = JSON.parse(family1VariantMapJson.textContent);

    let currentVariant = family1VariantMap['currentVariant'];
    let sp = currentVariant.split('_');

    /**
     * alle disable
     */
    document.querySelectorAll('[data-alvine-role="family1-list-button"]').forEach(element => {
        element.classList.remove('active');
        element.classList.remove('btn-secondary');
        element.classList.add('btn-outline-secondary');
    });

    sp.forEach((element) => {
        let button = document.querySelector('[data-alvine-role="family1-list-button"][data-alvine-value="' + element + '"]');
        if (button) {
            //button.classList.remove('disabled');
            button.classList.add('btn-secondary');
            button.classList.add('active');
        }
    });

    

}