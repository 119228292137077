
export { postJson };
export { handleErrorMessage };

/**
 * POST 
 * 
 * @param {*} actionURL 
 * @param {*} dataset 
 * 
 * @returns Promise
 */
function postJson(actionURL, dataset) {
    return fetch(actionURL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(dataset)
    }).then(response => {
        if (response.ok) {
            return response.text();
        }
        return Promise.reject(response);
    }).then(body => {
        try {
            return JSON.parse(body);
        } catch (error) {
            return Promise.reject('json fail');
        }
    }).then(function (json) {
        return Promise.resolve(json);
    })
}

/**
 * handleErrorMessage
 * @param {*} data 
 * @returns messages
 */
function handleErrorMessage(data) {

    let dataset = data?.dataset;
    let sysValidationReport = data?.sys?.validation?.report;
    let messages = [];
    /**
     * Fehlermeldungen im dataset
     */
    if (dataset !== undefined) {
        Object.values(dataset).forEach(function (values, key) {
            let validationReport = values?.sys?.validation?.report;
            if (validationReport !== undefined) {
                Object.values(validationReport).forEach(function (v, key) {
                    messages.push(v.message);
                });
            }
        });
    }
    if (sysValidationReport !== undefined) {
        Object.values(sysValidationReport).forEach(function (values, key) {
            if (values.message) {
                messages.push(values.message);
            }

        });
    }

    return messages;
}
