
/**
 * Copyright schukai GmbH and contributors 2024. All Rights Reserved.
 * Node module: @schukai/monster
 * This file is licensed under the AGPLv3 License.
 * License text available at https://www.gnu.org/licenses/agpl-3.0.en.html
 */

import {
    ATTRIBUTE_ROLE,
    CustomControl,
    Observer,
    assembleMethodSymbol,
    instanceSymbol,
    internalSymbol,
    registerCustomElement
} from "../_imports.js";

import { Shoppingcart } from './_order.js';
import { Price } from './_price.js';

export { OrderForm };

/**
 * @private
 * @type {symbol}
 */
const orderFormElementSymbol = Symbol("orderFormElement");

/**
 * OrderForm
 */
class OrderForm extends CustomControl {

    get defaults() {
        return Object.assign({}, super.defaults, {
            iid: null,
            priceData: null,
            currentPrice: null,
            isMulti: false,
            maxPrice: null,
            hint: null,
            minPrice: null,
            templates: {
                main: getTemplate(),
            },
        });
    }

    /**
     *
     * 
     */
    [assembleMethodSymbol]() {
        const self = this;
        super[assembleMethodSymbol]();
        initControlReferences.call(this);
        initEventHandler.call(this);

        const cartObj = new Shoppingcart();
        cartObj.initCartForm(this[orderFormElementSymbol]);

        loadPrices.call(self, 1, 'false').then(function (data) {
            displayMultiPrice.call(self);
        });

        /**
         * Button sperren
         */
        let currentVariant = getSelectedVariant.call(self);
        if (currentVariant === '') {
            console.log('no variant was selected');
            disableSubmitButton.call(self);
        }


        /**
         * is called when options changed
         */
        self[internalSymbol].attachObserver(
            new Observer(function () {
                /**
                 * current preis aktualisieren
                 * wenn die Preise neu geladen wurden
                 */
                let price = '';
                let variant = getSelectedVariant.call(self);
                if (variant !== '') {
                    price = self.getOption('priceData')[self.getOption('iid')][variant];
                } else {
                    /**
                     * günstigesten preis setzen
                     */
                    price = self.getOption('minPrice');
                }
                if (self.getOption('currentPrice') !== price) {
                    self.setOption('currentPrice', price);
                }
            }),
        );

        return this;
    }

    /**
     * This method is called by the `instanceof` operator.
     * @returns {symbol}
     * @since 2.1.0
     */
    static get [instanceSymbol]() {
        return Symbol.for(
            "@schukai/monster/custom/custom-orderform@@instance",
        );
    }

    static getTag() {
        return "monster-custom-orderform";
    }

}

/**
 * @private
 */
function initControlReferences() {
    this[orderFormElementSymbol] = this.querySelector(
        `[${ATTRIBUTE_ROLE}=orderform]`,
    );
}

/**
 * button sperren
 */
function disableSubmitButton() {
    let submitButton = this.querySelector('[data-alvine-role="submitbutton"]');
    this.setOption('hint', 'Bitte wählen Sie eine Variante aus');
    submitButton.setOption('disabled', true);
}

/**
 * button sperren
 */
function enableSubmitButton() {
    let submitButton = this.querySelector('[data-alvine-role="submitbutton"]');
    this.setOption('hint', '');
    submitButton.setOption('disabled', false);
}

/**
 * @private
 * @return {initEventHandler}
 */
function initEventHandler() {
    const self = this;
    const form = this[orderFormElementSymbol];

    let submitbutton = this.querySelector('[data-alvine-role="submitbutton"]');
    if (submitbutton) {
        submitbutton.addEventListener('click', function (event) {
            form.requestSubmit();
        });
    }

    /**
     * Mengenänderung
     */
    this.querySelectorAll('[data-alvine-role="positionCount-dec"]').forEach(function (positionCountDec) {
        if (positionCountDec != null) {
            positionCountDec.addEventListener('click', function (event) {
                let countChange = self.querySelector('[data-alvine-role="priceCalcCount"]');
                if (countChange) {
                    let value = parseInt(countChange.value);
                    value = value-1;
                    if(value<1){
                        value=1;
                    }
                    countChange.value = value
                    countChange.dispatchEvent(new Event('change'));
                }
            });
        }
    });
    this.querySelectorAll('[data-alvine-role="positionCount-inc"]').forEach(function (positionCountInc) {
        if (positionCountInc != null) {
            positionCountInc.addEventListener('click', function (event) {
                let countChange = self.querySelector('[data-alvine-role="priceCalcCount"]');
                if (countChange) {
                    let value = parseInt(countChange.value);
                    value = value+1;
                    countChange.value = value
                    countChange.dispatchEvent(new Event('change'));
                }
            });
        }
    });

    /**
     * Variant Select
     */
    this.querySelectorAll('[data-alvine-role="variantSelect"]').forEach(function (variantSelectorElement) {
        if (variantSelectorElement != null) {
            variantSelectorElement.addEventListener('click', function (event) {
                let variant = this.getAttribute('data-alvine-itemvariant');
                let price = self.getOption('priceData')[self.getOption('iid')][variant];
                self.setOption('currentPrice', price);
                enableSubmitButton.call(self);
                hideDisplayMultiPrice.call(self);
            });
        }
    });
 
    /**
     * bei mänge änderung preise neu laden
     */
    let countChange = this.querySelector('[data-alvine-role="priceCalcCount"]');
    if (countChange) {
        countChange.addEventListener('change', function (event) {
            let count = this.value;
            loadPrices.call(self, count, 'true');
        });
    }

    return this;
};

/**
 * selected variant
 * 
 * @returns string
 */
function getSelectedVariant() {
    let self = this;
    let variant = '';
    this.querySelectorAll('[data-alvine-role="variantSelect"]').forEach(function (variantSelectorElement) {
        
        /**
         * single variant
         */
        if(variantSelectorElement.tagName==='INPUT' && variantSelectorElement.value!==''){
            variant = variantSelectorElement.value
        }

        /**
         * multiple variant via radio 
         */
        if (variantSelectorElement.checked) {
            variant = variantSelectorElement.getAttribute('data-alvine-itemvariant');
        }
    });
    return variant;
};

/**
 * multiple prices available
 */
function displayMultiPrice() {
    if (this.getOption('isMulti') === true) {
        showDisplayMultiPrice.call(this);
    } else {
        hideDisplayMultiPrice.call(this);
    }
};

function hideDisplayMultiPrice() {
    let iid = this.getOption('iid');
    let itemEleMultiPrice = document.querySelector('[data-alvine-role="itemMultiPrice"][data-iid="' + iid + '"]');
    if(itemEleMultiPrice){
        itemEleMultiPrice.style.display = "none";
    }
    
}

function showDisplayMultiPrice() {
    let iid = this.getOption('iid');
    let itemEleMultiPrice = document.querySelector('[data-alvine-role="itemMultiPrice"][data-iid="' + iid + '"]');
    if(itemEleMultiPrice){
        itemEleMultiPrice.style.display = "inherit";
    }
}

/**
 * loadPrices methode vom Price Modul verwenden und formatieren
 * 
 * @param {*} count 
 * @param {*} calcprice 
 * 
 * @returns Promise
 */
function loadPrices(count, calcprice) {
    const self = this;
    const iid = self.getOption('iid');
    const price = new Price(iid);

    return price.loadPrices(iid, count, calcprice).then(function (data) {

        let priceData = data.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData
        let entries = priceData.entries
        let result = {};
        let variants = {};

        Object.keys(entries).forEach(function (d) {
            let entry = entries[d];
            variants[entry['variant']] = entry['price']['grossFormatted'];
        });

        result[iid] = variants;

        /**
         * multiple prices available
         */
        let isMulti = priceData.isMulti;
        self.setOption('isMulti', isMulti);

        let maxPrice = priceData.maxPrice?.price?.grossFormatted;
        if (maxPrice) {
            self.setOption('maxPrice', maxPrice);
        }
        let minPrice = priceData.minPrice?.price?.grossFormatted;
        if (minPrice) {
            self.setOption('minPrice', minPrice);
        }

        /**
         * set price data
         */
        self.setOption('priceData', result);

        return data;
    });

}

/**
 * @private
 * @return {string}
 */
function getTemplate() {
    return `
        <div data-monster-role="control" part="control" tabindex="0" >
     
                <slot></slot>

        </div>`;
}

registerCustomElement(OrderForm);
