import { Embed, getDocumentTranslations } from '../_imports.js';

document.addEventListener('click', function (event) {
    let button = event.target;

    if (event.target.getAttribute('data-alvine-role') === 'nextTransitionButton') {
        let nexttransition = button.getAttribute('data-alvine-transition');
        let href = button.getAttribute('data-alvine-href');
        if (href === null) {
            href = "/checkout";
        }
        button.hideDialog();
        button.setState('activity');
        
        doTransition(nexttransition, []).then(function (data) {
            button.setState('successful', 2000);
            window.location.href = href;
        }).catch(function (response) {
           
            response.text().then(function(text){
                let error_json = JSON.parse(text);
                let code = error_json?.sys?.workflow?.code;
                if (code === '3') {
                    /**
                     * status ist schon erreicht
                     */
                    window.location.href = href;
                } else if (code === '4') {
                    /**
                     * keinen Token mehr übergeben
                     */
                    window.location.href = '/';
                } else {
                    button.setState('failed', 2000);
                    Embed.assignTranslationsToElement().then(() => {
    
                        let translations = getDocumentTranslations();
                        let report = error_json?.sys?.validation?.report;
                        var message = [];
                        if (report !== undefined) {
                            Object.values(report).forEach(function (element, key) {
                                var msg = element.message;
                                try {
                                    msg = translations.getText(msg);
                                } catch (error) {
    
                                }
                                message.push(msg);
                            });
                        }
                        if (message.length > 0) {
                            
                            button.setMessage(message.join('<br>'));
                            button.showDialog()
                        }
    
                    }).catch((e) => {
    
                    })
                }
            });

        });
    }

});


// let nextTransitionButton = document.querySelectorAll('[data-alvine-role="nextTransitionButton"]');
// if (nextTransitionButton !== undefined) {
//     nextTransitionButton.forEach(function (obj) {
//         obj.addEventListener('click', function () {
//             debugger;

//         });
//     });
// }

export function doTransition(transistion, data) {
    // die facet Order wird am schluss benötigt für den Redirect auf die DONE seite
    var actionURL = '/api/storefront/plugin/checkout/transition/' + transistion ;
    var self = this;

    return fetch(actionURL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    }).then(function (response) {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        });

}