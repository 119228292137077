import { Embed, Formatter, Updater, addToObjectLink, findTargetElementFromEvent, getDocumentTranslations, getLinkedObjects } from './_imports.js';
import { postJson,handleErrorMessage } from './_libs.js';

document.querySelectorAll('[data-alvine-role="contact-form"]').forEach(function (element) {
    var obj = {
        dataset: [
            {
                title: "",
                alias: "",
                salutation: "",
                formOfAddress: "",
                firstname: "",
                name1: "",
                name2: "",
                custom1: "",
                custom2: "",
                birthday: "",
                message:"",
                mailAddress:""
            }
        ]
    };
    var updater = new Updater(element, obj);
    addToObjectLink(element, Symbol.for('contact-form'), updater);
});

/**
 * submit Events onbeforesubmit
*/
document.addEventListener("submit", function (event) {

    let form = event.target;
 
    /**
     * form-submit-button event kommt aus _account.js
     */
    let submitButton = form.querySelector('[data-alvine-role="form-submit-button"]');


    if (form.getAttribute('data-alvine-role') === 'contact-form') {

        event.preventDefault();
        submitButton.hideDialog();
        submitButton.setState('activity');

        let iterator = getLinkedObjects(form, Symbol.for('contact-form'));
        let updater = iterator.next().value;

        /**
         * Refresh values
         */
        updater.retrieve();
        let data = updater.getSubject();

        handleContactForm(form, data,submitButton);
    }

});

/**
 * handleUserRegister
 * @param {*} data 
 * @returns 
 */
function handleContactForm(form, dataset, submitButton) {
 
    postJson('/api/storefront/customisation/contactform', dataset)
        .then(function (json) {
            form.reset();
            submitButton.setState('successful', 4000);
            submitButton.setMessage('Ihre Nachricht wurde erfolgreich versendet');
            submitButton.showDialog();
            setTimeout(() => {
                submitButton.hideDialog();
            }, 4000);
        })
        .catch((response) => {
            response.json().then(function (data) {
                let messages = handleErrorMessage(data);
                submitButton.setState('failed', 2000);
                submitButton.setMessage(messages.join('<br>'));
                submitButton.showDialog();
                setTimeout(() => {
                    submitButton.hideDialog();
                }, 2000);
            });
            submitButton.setState('failed', 2000);
        });

}