import { Updater } from '../_imports.js';

export class Price {

    constructor(iidList) {
        var self = this;
        self.storefrontPriceUrl = "/api/storefront/plugin/storefront/commerce/items?iids={iids}&facet=price&count={count}&calcprice={calcprice}";

        self.iidList = iidList;
        self.priceDataSummary = {};
    }

    init() {

        var self = this;
        let body = document.querySelector('body');
        let updater = new Updater(body, self.priceDataSummary);

        //init empty prices
        self.iidList.forEach(function(iid) {
            let data = {
                priceCalc: {
                    displayPrice: 0,
                    variantSelected: 0,
                    displayPriceSingle: "",
                    displayPriceOriginal: "",
                }
            };
            self.priceDataSummary[iid] = data;
        });
        updater.run().then(() => {
            updater.enableEventProcessing();
        });


        self.loadPrices(self.iidList.join()).then(function(result) {
            self.iidList.forEach(function(iid) {

                /* to be discussed - decision price type */
               var itemSingleMinPrice = result?.apiStorefrontCommerceItemPrice?.dataset[iid]?.enrichment?.priceData?.minPrice?.price?.grossFormatted;
               console.log(itemSingleMinPrice);
               if(itemSingleMinPrice!==undefined){
               
               
                /* offer price available for min price variant */ 
                var itemSingleMinOriginalPrice = null;
                if(result.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData.minPrice.original!=undefined){
                    var itemSingleMinOriginalPrice = result.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData.minPrice.original.grossFormatted;
                }

                //multiple prices available
                var itemIsMulti = result.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData.isMulti;
                if(itemIsMulti){
                    document.querySelectorAll('[data-alvine-role="itemMultiPrice"][data-iid="'+iid+'"]').forEach(function (element) {
                        element.style.display = "inline";
                    });  
                }
                
                //offer prices available
                if(itemSingleMinOriginalPrice != null){
                    document.querySelectorAll('[data-alvine-role="itemOfferPrice"][data-iid="'+iid+'"]').forEach(function (element) {
                        element.style.display = "inherit";
                    }); 
                    document.querySelectorAll('[data-alvine-role="itemOfferPriceHolder"][data-iid="'+iid+'"]').forEach(function (element) {
                        element.style.display = "inline";
                    });        
                }
                
                let data = {
                    priceCalc: {
                        displayPrice: 0,
                        variantSelected: 0,
                        displayPriceSingle: itemSingleMinPrice,
                        displayPriceOriginal: itemSingleMinOriginalPrice,
                        //isMulti: localeItemPriceMulti
                    },
                    entries: result.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData.entries
                };

                self.priceDataSummary[iid] = data;
            }
            });

            updater.run().then(() => {
                updater.enableEventProcessing();
            });

        });

    }

    /**
     * 
     * @param {*} iids 
     * @param {*} count 
     * @param {*} calcprice 
     * @returns 
     */
    loadPrices(iids, count, calcprice) {
        if(count===undefined) {
            count = 1;
        }
        if(calcprice===undefined) {
            calcprice = 'false';
        }

        var url = this.storefrontPriceUrl.replace("{calcprice}", calcprice);
        var url = url.replace("{count}", count);
        var url = url.replace("{iids}", iids);

        return fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }).then((response) => {
            if(response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        }).then(data => {
            var result = {
                apiStorefrontCommerceItemPrice: {
                    dataset: {

                    }
                }
            };
            Object.keys(data.dataset).forEach(function(uuid) {
                Object.keys(data.dataset[uuid].dataset).forEach(function(iid) {
                    var item = data.dataset[uuid].dataset[iid];
                    /**
                     * Wert für den Counter
                     */
                    result.apiStorefrontCommerceItemPrice.dataset[iid] = item;
                })
            });
            return result;
        }).catch((response) => {
        })
    }

    
    initVariantEvents = function(itemObj){
        
        self = this;
        let iid = itemObj.getAttribute('data-iid');
        if (iid !== null) {
            self.iidList.push(iid);
        }
        
        var updaterData = {};
        var pricedata = {
            priceCalc:{
                variantSelected:0
            }
        };
        updaterData[iid]=pricedata;

        let updater = new Updater(itemObj, updaterData);
        let subject = updater.getSubject();

        let countChange = itemObj.querySelector('[data-alvine-role="priceCalcCount"]');
        let display = itemObj.querySelector('[data-alvine-role="priceCalcDisplay"]');

        itemObj.querySelectorAll('[data-alvine-role="variantSelect"]').forEach(function (variantSelectorElement) {
            if(variantSelectorElement!=null){
                variantSelectorElement.addEventListener('click', function (event) {

                    var variant = this.getAttribute('data-alvine-itemvariant');
                    var variantSelected = self.getVariant(variant,self.priceDataSummary[iid].entries);
                    
                    //single price selection for variant
                    subject[iid].priceCalc.displayPriceSingle = variantSelected.price.grossFormatted;
                    //save selected variant for later
                    subject[iid].priceCalc.variantSelected = variantSelected;
                                      
                    //entries - update total price
                    var totalprice = 0;
                    if(subject[iid].entries){
                        for (const [key, value] of Object.entries(subject[iid].entries)) {
                            //console.log(`${key}: ${value}`);
                            var variantSelected = self.getVariant(variant,subject[iid].entries);
                            if(value.variant===variantSelected.variant){
                                totalprice = value.price.grossFormatted;
                            }
                        }
                    }

                    subject[iid].priceCalc.displayPrice = totalprice;
                    if(totalprice>0){
                        display.removeAttribute('hidden');                         
                    }

                 
                });
            }
        });


        if(countChange!=null){
            countChange.addEventListener('change', function (event) {
                let count = this.value;

                self.loadPrices(iid, count, 'true').then(function (result) {

                    var variantCount = Object.keys(result.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData.entries).length;
                    //unique variant - always selected
                    if(variantCount===1){
                        var variant = self.priceDataSummary[iid].entries[Object.keys(self.priceDataSummary[iid].entries)[0]].variant;
                        var variantSelected = self.getVariant(variant,self.priceDataSummary[iid].entries);
                        subject[iid].priceCalc.variantSelected = variant;
                        
                        //single price selection for variant
                        subject[iid].priceCalc.displayPriceSingle = variantSelected.price.grossFormatted;
                    }else{
                        //multiple variants available - no variant selected yet
                        if(subject[iid].priceCalc.variantSelected === 0){
                            //no price update - no variant selected
                            return;
                        }
                    }
                    
                    //updates entries in global path
                    subject[iid].entries=result.apiStorefrontCommerceItemPrice.dataset[iid].enrichment.priceData.entries;
                    
                    for (const [key, value] of Object.entries(subject[iid].entries)) {
                        var variantSelectedString = subject[iid].priceCalc.variantSelected;
                        if(value.variant===variantSelectedString){
                            var totalprice = value.price.grossFormatted;
                        }
                    }

                    subject[iid].priceCalc.displayPrice = totalprice;
                    display.removeAttribute('hidden');
                });
            });
        }
        
        updater.run().then(() => {
          updater.enableEventProcessing();
        });    
    }
    
    

    /***
     * 
     * @param {type} variant
     * @param {type} variantEntries
     * @returns {Item.getVariant.value}
     */
    getVariant(variant, variantEntries) {
        for(const [key, value] of Object.entries(variantEntries)) {
            if(value.variant===variant) {
                return value;
            }
        }
    }

}



