import { Fetch, Formatter } from '../_imports.js';

export class Shoppingcart {

    /**
     * 
     * @param Obj | NodeList orderForm 
     */
    constructor(orderForm) {
        var self = this;
        self.cartForm = [];

        if (NodeList.prototype.isPrototypeOf(orderForm) === true) {
            orderForm.forEach(function (of) {
                self.cartForm.push(of);
            });
        } else {
            if (orderForm !== undefined) {
                self.cartForm.push(orderForm);
            }
        }
    }

    initCartForm = function (cartFormObj) {
        var self = this;
        
        if (cartFormObj !== undefined) {
            self.cartForm.push(cartFormObj);
        }
        self.cartForm.forEach(function (cartFormElement) {
            cartFormElement.addEventListener('submit', function (e) {
                e.preventDefault();
                var formData = new FormData(this);
                var data = Object.fromEntries(formData.entries());
                var token = self.getTokenFromCookie();
                var submitbutton = e.submitter;
                if (submitbutton === null) {
                    submitbutton = cartFormElement.querySelector('[data-alvine-role="submitbutton"]');
                }

                self.showSpinner.call(this, submitbutton);

                //verify existing token
                if (token != undefined) {
                    self.verifyTokenFromCookie(token).then(function () {
                        self.updateCartPosition.call(self, data, submitbutton, cartFormElement);
                    }).catch((reason) => {
                        self.createToken().then(function () {
                            console.log('create new token - invialid token');
                            self.updateCartPosition.call(self, data, submitbutton, cartFormElement);
                        });
                    });
                } else if (token === undefined) {
                    /**
                     * If there is no token - create token cookie
                     */
                    self.createToken().then(function () {
                        console.log('create new token - undefined token');
                        self.updateCartPosition.call(self, data, submitbutton, cartFormElement);
                    });
                }

            });

        });

        // document.addEventListener('shoppingCartChanged', function () {
        //     debugger;
        //     self.loadShoppingcartPopup.call(this);
        // });

    }
 
    updateCartPosition(data, button, orderForm) {

        var transistion = data.transition;
        if (transistion === undefined) {
            throw Error('error:cart transition undefined');
        }
        var actionURL = '/api/storefront/plugin/order/checkout/transition/' + transistion + '?juristication=de&facet=volatilePositions&expect=shoppingcart';

        var self = this;

        var errorWrapper = orderForm.querySelector('[data-alvine-role="carterror"]');
        if (errorWrapper != undefined) {
            errorWrapper.setAttribute('hidden', 'hidden');
            errorWrapper.innerHTML = "";
        }

        return fetch(actionURL, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then(data => {
                /**
                 * Event trigger
                 */
                var eventShoppingCartReload = new Event('shoppingCartChanged');
                document.dispatchEvent(eventShoppingCartReload);

                self.successfulSpinner.call(this, button);

            }).catch((response) => {

                if (response.status === 500) {
                    self.errorSpinner.call(this, button);
                    var errorWrapper = orderForm.querySelector('[data-alvine-role="carterror"]');
                    errorWrapper.removeAttribute('hidden');
                    errorWrapper.innerHTML = ('Ein unerwarteter Fehler ist aufgetreten.');
                    return;
                }

                /**
                 * display error messages
                 */
                self.showShoppingcartError.call(self, response, button, orderForm);
            });
    }

    removeFromCart(scid) {
        var actionURL = '/api/storefront/plugin/order/checkout/transition/shoppingcart-delete?juristication=de&facet=volatilePositions&expect=shoppingcart';

        var data = {
            'scid': scid
        };

        return new Promise(function (resolve, reject) {
            fetch(actionURL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            }).then(function (response) {

                if (!response.ok) {
                    throw Error();
                }

                var eventShoppingCartReload = new Event('shoppingCartChanged');
                document.dispatchEvent(eventShoppingCartReload);

                resolve();
            }).catch((e) => {
                console.log('error:removeFromCart');
            });
        });
    }

    showShoppingcartError(response, button, orderForm) {

        var self = this;

        try {

            if (Fetch !== undefined && Formatter !== undefined) {

                //todo - define locale url in constructor - overwrite in customer class
                var provider = new Fetch('/themes/aszaun/resources/de.json');

                response.json().then(function (data) {
                    provider.getTranslations('de').then(function (translation) {

                        var formatter = new Formatter({}, translation);
                        var messages = [];
                        if (data.dataset != undefined) {

                            Object.values(data).forEach(function (values, key) {

                                if (!values.validation) {
                                    return;
                                }

                                var validation = values.validation;
                                if (validation && validation.report) {
                                    Object.values(validation.report).forEach(function (v, key) {
                                        var message = v.message;
                                        message = message.replace('{', '');
                                        message = message.replace('}', '');
                                        try {
                                            message = formatter.format(message);
                                        } catch (e) {

                                        }
                                        messages.push(message);
                                    });

                                }
                            });
                        }
                        /**
                         * render errormsg
                         */
                        var errorWrapper = orderForm.querySelector('[data-alvine-role="carterror"]');
                        errorWrapper.removeAttribute('hidden');
                        errorWrapper.innerHTML = messages.join('<br>');

                        self.errorSpinner.call(this, button);
                    });

                    var eventShoppingCartReload = new Event('addShoppingcartFormError');
                    document.dispatchEvent(eventShoppingCartReload);
                });
            }

        } catch (e) { }

        self.errorSpinner.call(this, button);
    }


    createToken() {
        return new Promise(function (resolve, reject) {
            fetch('/api/storefront/plugin/order/token', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            }).then(function () {
                resolve();
            });
        });
    }

    getTokenFromCookie() {
        var a = 'token';
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : undefined;
    }

    verifyTokenFromCookie(token) {
        return new Promise(function (resolve, reject) {
            fetch('/api/storefront/plugin/order/token/' + token, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            }).then(function (response) {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(response.status);
                }
            })
        });
    }

    showSpinner(submitButton) {
        if (typeof submitButton.setState === 'function') {
            submitButton.setState('activity');
        } else {
            var orderFormSpinner = submitButton.querySelector('[data-alvine-role="orderformSpinner"]');
            if (orderFormSpinner != "undefined") {
                submitButton.classList.add('loading');
            }
        }


    }
    successfulSpinner(submitButton) {
        if (typeof submitButton.setState === 'function') {
            submitButton.setState('successful');
        } else {
            var orderFormSpinner = submitButton.querySelector('[data-alvine-role="orderformSpinner"]');
            if (orderFormSpinner != "undefined") {
                submitButton.classList.remove('loading');
            }
        }


    }
    errorSpinner(submitButton) {
        if (typeof submitButton.setState === 'function') {
            submitButton.setState('failed');
        } else {
            var orderFormSpinner = submitButton.querySelector('[data-alvine-role="orderformSpinner"]');
            if (orderFormSpinner != "undefined") {
                submitButton.classList.remove('loading');
            }
        }


    }

    /**
    * Warenkorb Popup laden und aktualisieren
    * 
    * @returns {undefined}
    */
    loadShoppingcartPopup() {
        debugger;
        var loader = document.querySelector('#shoppingcart-loader');
        if (loader) {
            loader.fetch().then(function (data) {
            });
        }
    }


}

export class Order {

    constructor(orderForm) {
        var self = this;
        self.orderForm = orderForm;
    }

    initOrderForm = function (orderObj) {

        var self = this;
        if (orderObj !== undefined) {
            self.orderForm.push(orderObj);
        }

        self.orderForm.forEach(function (orderFormElement) {

            var orderFormTermsAndConditions = orderFormElement.querySelector('[data-alvine-role="termsandconditions-checkbox"]');
            orderFormElement.addEventListener('click', function (e) {
                try {
                    self.confirmTermsAndConditions.call(orderFormTermsAndConditions);
                } catch (e) {
                    console.log("error terms-and-conditions:" + e);
                }
            });

            orderFormElement.addEventListener('submit', function (e) {
                e.preventDefault();
                self.submitOrder(this);
            });

        });

    }

    /**
     * Confirm terms and conditions
     * @returns {undefined}
     */
    confirmTermsAndConditions = function () {
        var self = this;

        var confirmed = self.checked === true ? 'true' : 'false';

        fetch('/api/storefront/plugin/checkout/equipment/terms-and-conditions?facet=termsAndConditions&confirmed=' + confirmed, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        })
            .then(response => response.json())
            .then(data => {

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    /**
     * Create order
     * @returns {undefined}
     */
    submitOrder = function (orderObj) {

        var self = this;

        jQuery('[data-alvine2-buyform-error]').html('');

        self.addSpinner(jQuery('[for="submit_overviewForm"]'));

        var createOrderURL = '/api/storefront/plugin/checkout/transition/order-confirm?facet=termsAndConditions,address,customer,state,termsAndConditions,order';

        fetch(createOrderURL, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }).then((response) => {
            // 1. check response.ok
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response); // 2. reject instead of throw
        }).then(data => {
            /**
             * Weiter leiten auf die DONE Seite
             */
            var oid = data.dataset.token.container.equipments.order.oid;
            if (oid === undefined) {
                self.removeSpinner(jQuery('[for="submit_overviewForm"]'));
                //UIkit.modal.alert('<h3>Fehler</h3>Ein unerwarteter Fehler ist aufgetreten');
            } else {
                window.location = ('/checkout/done/' + oid);
            }
        }).catch((response) => {

            self.errorSpinner(jQuery('[for="submit_overviewForm"]'));

            if (response.status === 500) {
                self.removeSpinner(jQuery('[for="submit_overviewForm"]'));

                return;
            }

            response.json().then(function (data) {
                var messages = data.sys.validation.report;
                try {
                    getMonster().then(function () {
                        if (MonsterFetch !== undefined) {
                            var msg = [];
                            Object.values(messages).forEach(function (element, key) {
                                var provider = new MonsterFetch('/themes/alvine/resources/de.json');

                                provider.getTranslations('de').then(function (translation) {
                                    var formatter = new Formatter({}, translation);
                                    var message = element.message;
                                    message = message.replace('{', '');
                                    message = message.replace('}', '');
                                    try {
                                        message = formatter.format(message);
                                    } catch (e) { }
                                    msg.push(message);
                                    self.removeSpinner(jQuery('[for="submit_overviewForm"]'));
                                    jQuery('[data-alvine-role="buyform-error"]').html(msg.join('<br>')).show();
                                });
                            });
                        } else {
                            //na
                        }
                    });
                } catch (error) {
                    //na
                }
            });
        });
    }


    addSpinner = function (element) {
        var spinner = jQuery('<div class="spinner-border spinner-border-sm ms-2" role="status"><span class="sr-only"></span></div>');

        jQuery(element).addClass('disabled');
        jQuery(element).append(spinner);
    }

    removeSpinner = function (element) {
        jQuery(element).find(".spinner-border").remove()
        jQuery(element).removeClass('disabled');
    }

    errorSpinner = function (element) {
        removeSpinner(element);
    }


}