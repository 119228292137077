
import { Embed, Formatter, Updater, addToObjectLink, findTargetElementFromEvent, getDocumentTranslations, getLinkedObjects } from './_imports.js';
import { doTransition } from "./modules/_transition.js";
import { postJson } from "./_libs.js";


const localeUrl = '/themes/errepi/resources/de.json';


document.querySelectorAll('[data-alvine-role="address-form"]').forEach(function (element) {

    var obj = {
        dataset: [
            {
                type: 4,
                contact: "",
                company: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
            },
            {
                type: 3,
                contact: "",
                company: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
            }
        ]
    };
    var updater = new Updater(element, obj);
    addToObjectLink(element, Symbol.for('address-form'), updater);
});

document.querySelectorAll('[data-alvine-role="personal-form"]').forEach(function (element) {

    var obj = {
        dataset: [
            {
                pid: null,
                title: "",
                alias: "",
                salutation: "",
                formOfAddress: "",
                firstname: "",
                name1: "",
                name2: "",
                custom1: "",
                custom2: "",
                bithday: ""
            }
        ]
    };
    var updater = new Updater(element, obj);
    addToObjectLink(element, Symbol.for('personal-form'), updater);
});

document.querySelectorAll('[data-alvine-role="register-form"]').forEach(function (element) {

    var obj = {
        dataset: [
            {
                user: {
                    name: "",
                    password: "",
                    passwordRepeat: ""
                },
                personal: {
                    title: "",
                    alias: "",
                    salutation: "",
                    formOfAddress: "",
                    firstname: "",
                    name1: "",
                    name2: "",
                    custom1: "",
                    custom2: "",
                    bithday: ""
                },
                billingAddress: {
                    contact: "",
                    company: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                    preset: 1,
                    type: 4
                },
                communication: {
                    mailAddress: ""
                }
            }
        ]
    };
    var updater = new Updater(element, obj);
    addToObjectLink(element, Symbol.for('register-form'), updater);
});

document.querySelectorAll('[data-alvine-role="checkout-guest-form"]').forEach(function (element) {

    var obj = {
        dataset: [
            {
                user: {
                    name: "",
                    password: "",
                    passwordRepeat: ""
                },
                personal: {
                    title: "",
                    alias: "",
                    salutation: "",
                    formOfAddress: "",
                    firstname: "",
                    name1: "",
                    name2: "",
                    custom1: "",
                    custom2: "",
                    birthday: ""
                },
                billingAddress: {
                    contact: "",
                    company: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                    preset: 1,
                    type: 4
                },
                communication: {
                    mailAddress: ""
                }
            }
        ]
    };
    var updater = new Updater(element, obj);
    addToObjectLink(element, Symbol.for('checkout-guest-form'), updater);


});


let deliveryaddressCardInfo = document.querySelector('[data-alvine-role="delivery-address-card-info"]');
let deliveryaddressCardForm = document.querySelector('[data-alvine-role="delivery-address-card-form"]');

document.addEventListener("DOMContentLoaded", () => {

    /**
     * wenn etwas definiert wurde das Feld anzeigen 
     */
    let deliveryAddress1 = undefined;
    let deliveryAddress2 = undefined;
    let deliveryAddress1Element = document.getElementById('delivery-address1');
    let deliveryAddress2Element = document.getElementById('delivery-address2');
    if (deliveryAddress1Element) {
        if (deliveryAddress1Element.value !== '') {
            deliveryAddress1 = deliveryAddress1Element.value;
        }

    }
    if (deliveryAddress2Element) {
        if (deliveryAddress2Element.value !== '') {
            deliveryAddress2 = deliveryAddress2Element.value;
        }
    }
    if (deliveryAddress1 !== undefined || deliveryAddress2 !== undefined) {
        document.querySelector('[data-alvine-role="delivery-address-add-button"]').click();
    }


});

document.addEventListener('click', function (event) {
    let obj = event.target;

    if (obj.getAttribute('data-alvine-role') === 'delivery-address-add-button') {
        if (deliveryaddressCardInfo && deliveryaddressCardForm) {
            deliveryaddressCardInfo.setAttribute('hidden', 'hidden');
            deliveryaddressCardForm.removeAttribute('hidden');
            document.querySelector('[data-alvine-role="deliveryAddressTitle"]').setAttribute('hidden', 'hidden');
            document.querySelector('[data-alvine-role="delivery-address"]').useDeliveryAddress = true;
        }
    }
    if (obj.getAttribute('data-alvine-role') === 'delivery-address-remove-button') {
        if (deliveryaddressCardInfo && deliveryaddressCardForm) {
            deliveryaddressCardInfo.removeAttribute('hidden');
            deliveryaddressCardForm.setAttribute('hidden', 'hidden');
            document.querySelector('[data-alvine-role="deliveryAddressTitle"]').removeAttribute('hidden');
            document.querySelector('[data-alvine-role="delivery-address"]').useDeliveryAddress = false;
        }
    }
    if (obj.getAttribute('data-alvine-role') === 'form-submit-button') {
        let form = obj.form;
        if (form instanceof HTMLFormElement) {
            /**
             * CustomValidity Reset
             * sonst kann es nicht abgeschickt werden
             */
            for (var formElement of form.querySelectorAll(':invalid')) {
                formElement.setCustomValidity('');
            }
            form.requestSubmit();
        }
    }

    /**
      * übernahme der Daten aus dem Formular
      */
    if (obj.getAttribute('data-alvine-role') === 'change-checkout-address-button') {
        event.preventDefault();

        document.querySelectorAll('.card.active').forEach(function (element) {
            element.classList.remove("active");
        });

        obj.closest('.card').classList.add("active");

        let type = obj.getAttribute('data-alvine-type');
        let form = obj.form;
        let formData = new FormData(form);
        let data = Object.fromEntries(formData.entries());
        let idKey;
        if (type === '3') {
            idKey = 'delivery';
            document.querySelector('[data-alvine-role="delivery-address-add-button"]').click();
        }
        if (type === '4') {
            idKey = 'billing';
        }

        document.getElementById(idKey + '-address1').value = '';
        document.getElementById(idKey + '-address2').value = '';
        document.getElementById(idKey + '-zipcode').value = '';
        document.getElementById(idKey + '-city').value = '';
        document.getElementById(idKey + '-country').value = '';


        document.getElementById(idKey + '-address1').value = data.address1;
        document.getElementById(idKey + '-address2').value = data.address2;
        document.getElementById(idKey + '-zipcode').value = data.zipcode;
        document.getElementById(idKey + '-city').value = data.city;
        document.getElementById(idKey + '-country').value = data.country;

    }

    let logoutButton = findTargetElementFromEvent(event, 'data-alvine-role', 'logout-button');

    if (logoutButton) {
        event.preventDefault();

        return fetch('/api/logout', {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Accept': 'text/html'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        })
            .then(function (response) {
                window.location = "";

            }).catch((response) => {
            });
    }

    let passwordForgottenButton = findTargetElementFromEvent(event, 'data-alvine-role', 'password-forgotten-button');
    if (passwordForgottenButton) {
        event.preventDefault();
        var usernameObj = document.querySelector('[name="username"]');
        if(usernameObj){
            var usernameObjValue = usernameObj.value;

            if (usernameObjValue === undefined || usernameObjValue === '') {
                usernameObj.classList.add('is-invalid');
                return;
            }
            doRecoverAccount(usernameObjValue);
        }
    }


})

/**
* Reset Validity
* mit dem Atttribute data-alvine-validation="reset-on-change" wird die Validität eines Feldes zurückgesetzt
*/
document.querySelectorAll('[data-alvine-validation="reset-on-change"]').forEach(function (element) {
    element.addEventListener('change', function (event) {
        const obj = event.target;
        element.setCustomValidity('');
    });
});


document.addEventListener('change', function (event) {
    const obj = event.target;
    if (obj.getAttribute('data-alvine-role') === 'formofaddress-select') {
        /**
         * Das Anrede Feld steuern
         */
        document.querySelector('[data-alvine-role="salutation-select"]').selectedIndex = obj.selectedIndex
    }
});

/**
 * submit Events onbeforesubmit
*/
document.addEventListener("submit", function (event) {

    let form = event.target;
    let submitButton = form.querySelector('[data-alvine-role="form-submit-button"]');

    if (form.getAttribute('data-alvine-role') === 'loginform') {
        event.preventDefault();
        let formData = new FormData(form);
        let data = Object.fromEntries(formData.entries());
        login(data);
    }

    /**
     * Password Reset
     */
    if(form.getAttribute('data-alvine-role') === 'passwordform') {
        event.preventDefault();
        let passwordObj = document.querySelector('[name="your-password"]');
        let passwordRepeatObj = document.querySelector('[name="repeat-your-password"]');
        let passwordformError = document.querySelector('[data-alvine-role="passwordform-error"]');

        passwordformError.setAttribute('hidden',true);
        passwordObj.classList.remove('is-invalid');
        passwordRepeatObj.classList.remove('is-invalid');

        let password = passwordObj.value;
        let passwordRepeat = passwordRepeatObj.value;
    
        if(password===undefined||password==='') {
            passwordObj.classList.add('is-invalid');
            return;
        }
        if(password!==passwordRepeat) {
            passwordRepeatObj.classList.add('is-invalid');
            passwordformError.removeAttribute('hidden');
            return;
        }
    
        doResetAccountPassword(password);
    }
    

    if (form.getAttribute('data-alvine-role') === 'register-form') {

        event.preventDefault();
        submitButton.hideDialog();
        submitButton.setState('activity');

        let iterator = getLinkedObjects(form, Symbol.for('register-form'));
        let updater = iterator.next().value;

        /**
         * Refresh values
         */
        updater.retrieve();
        let data = updater.getSubject();

        /**
         * password validation
         */
        if (data.dataset[0].user.password !== data.dataset[0].user.passwordRepeat) {
            document.querySelector('[name="passwordRepeat"]').setCustomValidity('Bitte geben Sie zweimal das gleiche Passwort ein');
            form.reportValidity();
            submitButton.setState('failed', 2000);
            return;
        }

        /**
         * die Mail Adresse isr auch der Benutzername
         */
        data.dataset[0].communication.mailAddress = data.dataset[0].user.name;

        if (submitButton.getAttribute('data-alvine-type') === 'checkout') {

            /**
             * innerhalb des Checkouts
             */
            doTransition('register-user', data).then(function (response) {
                window.location.reload();
            }).catch(function (response) {
                response.json().then(function (data) {
                    let messages = handleErrorMessage(data);
                    submitButton.setState('failed', 2000);
                    submitButton.setMessage(messages.join('<br>'));
                    submitButton.showDialog(2000);
                });
            })

        } else {
            handleUserRegister(data, submitButton);
        }


    }

    if (form.getAttribute('data-alvine-role') === 'personal-form') {
        event.preventDefault();

        var iterator = getLinkedObjects(form, Symbol.for('personal-form'));
        var updater = iterator.next().value;
        /**
         * update values
         */
        updater.retrieve();
        let data = updater.getSubject();

        changePersonal(data);
    }

    /**
     * Gast Checkout
     */
    if (form.getAttribute('data-alvine-role') === 'checkout-guest-form') {
        event.preventDefault();
        submitButton.hideDialog();
        submitButton.setState('activity');

        let iterator = getLinkedObjects(form, Symbol.for('checkout-guest-form'));
        let updater = iterator.next().value;

        /**
         * update values
         */
        updater.retrieve();
        let data = updater.getSubject();

        /**
         * set communications
         */
        data.dataset[0].communication.mailAddress = data.dataset[0].user.name;

        doTransition('guest-user', data).then(function (response) {
            window.location.reload();
        }).catch(function (response) {
            response.json().then(function (data) {
                let messages = handleErrorMessage(data);
                submitButton.setState('failed', 2000);
                submitButton.setMessage(messages.join('<br>'));
                submitButton.showDialog(2000);
            });
        })

    }
    if (form.getAttribute('data-alvine-role') === 'address-form') {
        event.preventDefault();
        submitButton.hideDialog();
        submitButton.setState('activity');

        var iterator = getLinkedObjects(form, Symbol.for('address-form'));
        var updater = iterator.next().value;
        /**
         * Werte aktualisieren
         */
        updater.retrieve();
        let data = updater.getSubject();

        if (document.querySelector('[data-alvine-role="delivery-address"]').useDeliveryAddress !== true) {
            data.dataset.splice(1);
        }



        doTransition('address-change', data).then(function (response) {
            let href = "/checkout";
            window.location.href = href;
        }).catch(function (response) {
            response.json().then(function (json) {


                let report = json?.sys?.validation?.report
                let code = json?.sys?.workflow?.code;

                submitButton.setState('failed', 2000);

                if (report !== undefined) {
                    Object.keys(report).forEach(function (key) {
                        let message = report[key].message;
                        Embed.assignTranslationsToElement().then(() => {
                            let translations = getDocumentTranslations();
                            let formatter = new Formatter({}, translations);
                            message = formatter.format(message);
                        }).catch((e) => {
                            debugger;
                        })
                    })
                }

            });
        });


        //     event.preventDefault();
        //     submitButton.hideDialog();
        //     submitButton.setState('activity');

        //     let obj = {
        //         dataset: []
        //     };
        //     let formData = new FormData(form);
        //     let data = Object.fromEntries(formData.entries());
        //     obj.dataset.push(data);

        //     doTransition('address-change', data).then(function (response) {
        //         let href = "/checkout";
        //         window.location.href = href;
        //     }).catch(function (response) {
        //         submitButton.setState('failed', 2000);
        //     })

    }

    if (form.getAttribute('data-alvine-role') === 'add-addressform') {
        event.preventDefault();

        var obj = {
            dataset: []
        };
        var self = this;
        var formData = new FormData(this);
        var data = Object.fromEntries(formData.entries());

        obj.dataset.push(data);

        addAddress(obj).then(function (response) {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        }).then(data => {
            window.location.reload();
        }).catch((response) => {
            response.json().then(function (data) {
                //  handleErrorMessage(data).then(function (messages) {
                //jQuery(self).find('[data-alvine-role="add-addressform-feedback"]').html(handleErrorMessage(data).join('<br>')).show();
                //});
            })
        });

    }
})



/**
 * 
 * @param {*} data 
 */
function handleErrorMessageFromEmbed(data) {
    return Embed.assignTranslationsToElement().then(() => {
        let translations = getDocumentTranslations();
        let formatter = new Formatter({}, translations);
        let messages = [];
        let dataset = data?.dataset;
        let sysValidationReport = data?.sys?.validation?.report;

        if (dataset !== undefined) {
            Object.values(dataset).forEach(function (values, key) {
                let validationReport = values?.sys?.validation?.report;
                if (validationReport !== undefined) {
                    Object.values(validationReport).forEach(function (v, key) {
                        var message = v.message;
                        try {
                            message = formatter.format(message);
                        } catch (e) {

                        }
                        messages.push(message);
                    });
                }
            });
        }

        if (sysValidationReport !== undefined) {
            Object.values(sysValidationReport).forEach(function (values, key) {
                var message = values.message;
                try {
                    message = formatter.format(message);
                } catch (e) {

                }
                messages.push(message);
            });
        }
        return Promise.resolve(messages);
    }).catch((e) => {
        debugger;
    })
};

/**
 * 
 * @param {*} data 
 * @returns 
 */
function addAddress(data) {

    var actionURL = '/api/storefront/plugin/storefront/account/address';
    var self = this;

    return fetch(actionURL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })




}

/**
 * 
 * @param {*} data 
 * @returns 
 */
function changePersonal(data) {

    var actionURL = '/api/storefront/plugin/storefront/account/personal';
    var self = this;

    return fetch(actionURL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })
        .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(data => {
            window.location = "";
        }).catch((response) => {
        });


}

/**
 * handleUserRegister
 * @param {*} data 
 * @returns 
 */
function handleUserRegister(dataset, submitButton) {

    postJson('/api/storefront/customisation/register', dataset)
        .then(function (json) {
            /**
             * seite neu laden
             */
            window.location.reload();
        })
        .catch((response) => {
            response.text().then(function (body) {
                try {
                    let json = JSON.parse(body);
                    handleErrorMessageFromEmbed(json).then(function (messages) {
                        submitButton.setMessage(messages.join('<br>'));
                        submitButton.showDialog(2000);
                    });
                } catch (error) {
                    return Promise.reject('json fail');
                }
            });
            submitButton.setState('failed', 2000);
        });

}



/**
 * 
 * @param {type} username
 * @returns 
 */
function doRecoverAccount(username) {

    let actionURL = '/api/storefront/customisation/request-access-token';
    let usernameObj = document.querySelector('[name="username"]');
    let passwordObj = document.querySelector('[name="password"]');
    let loginformObj = document.querySelector('[data-alvine-role="loginform-info"]');
    let logerrorObj = document.querySelector('[data-alvine-role="loginform-error"]');
    let loginformnosendObj = document.querySelector('[data-alvine-role="loginform-nosend"]');

    loginformnosendObj.setAttribute('hidden',true);
    loginformObj.setAttribute('hidden',true);
    logerrorObj.setAttribute('hidden',true);
    usernameObj.classList.remove('is-invalid');
    passwordObj.classList.remove('is-invalid');

    return fetch(actionURL, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        redirect: 'follow',
        body: JSON.stringify({
            username: username
        }),
        headers: new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json;'
        })
    })
        .then(function (response) {
            if (response.ok) {
                usernameObj.classList.add('is-valid');
                loginformObj.removeAttribute('hidden');
                
            } else {
                usernameObj.classList.add('is-invalid');
                logerrorObj.removeAttribute('hidden');
            }
            return response.json();
        })
        .then((data) => {
            let infoMessage = data?.dataset?.infoMessage;
            if(infoMessage===true) {

            }
            // if (typeof data.dataset.infoMessage !== 'undefined' && data.dataset.infoMessage === true) {
            //     //jQuery('[data-alvine-role="loginform-nosend"]').classList.remove('hidden');
            //     usernameObj.classList.remove('is-valid');
            //     loginformObj.attr('hidden', true);
            // }

        });

}

/**
 * login
 * @param {*} data 
 * @returns 
 */
function login(data) {

    let actionURL = '/api/storefront/plugin/order/token/login';
    let usernameObj = document.querySelector('[name="username"]');
    let passwordObj = document.querySelector('[name="password"]');
    let logerrorObj = document.querySelector('[data-alvine-role="loginform-error"]');
    var self = this;

    return fetch(actionURL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })
        .then(function(response) {
            if(response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(data => {

            /**
             * API ??
             */

            window.location = "";

        }).catch((response) => {

            usernameObj.classList.add('is-invalid');
            passwordObj.classList.add('is-invalid');
            logerrorObj.removeAttribute('hidden');

    });

}

/**
 * password reset
 * 
 * @param {*} password 
 * @returns 
 */
function doResetAccountPassword(password) {

    let actionURL = '/api/account/change-password';
    let passwordObj = document.querySelector('[name="your-password"]');
   
    let passwordformError = document.querySelector('[data-alvine-role="passwordform-error"]');
    let passwordformInfo = document.querySelector('[data-alvine-role="passwordform-info"]');
    let passwordformSubmitbutton = document.querySelector('[data-alvine-role="passwordform-submitbutton"]');

    passwordformError.setAttribute('hidden',true);

    return fetch(actionURL, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        redirect: 'follow',
        body: JSON.stringify({
            password: password
        }),
        headers: new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        })
    })
    .then(function(response) {
        if(response.ok) {
            passwordObj.classList.add('is-valid');
            passwordformInfo.removeAttribute('hidden');
            passwordformSubmitbutton.setAttribute('hidden',true);
        } else {
            passwordObj.classList.add('is-invalid');
            passwordformError.removeAttribute('hidden');
        }

    });

}